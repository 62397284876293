<template>
  <header class="elv-landing-subscription-page-header">
    <img src="@/assets/img/reports/default-entity-logo.png" class="elv-chain-icon-content__logo" />
    <span>{{ t('button.createProject') }}</span>
  </header>
  <div v-if="isWaitingPayment" class="elv-landing-subscription-waiting-payment">
    <p class="elv-landing-wait-payment-content">
      <SvgIcon name="hourglass" width="32" height="32" />
      <span class="elv-landing-wait-payment-content__title">{{ t('title.addPaymentMethod') }}</span>
      <span class="elv-landing-wait-payment-content__tip">{{ t('message.addPaymentMethodTip') }}</span>
      <el-button type="primary" round :loading="paymentLoading" @click="onBindCard">{{
        t('title.addPaymentMethod')
      }}</el-button>
      <span class="elv-landing-wait-payment-content__cancel" @click="onChangeCancelProjectDialogStatus">{{
        t('title.cancelProject')
      }}</span>
    </p>
  </div>
  <el-scrollbar v-else class="elv-landing-subscription-container">
    <div class="elv-landing-subscription-header">
      <p class="elv-landing-subscription-header__title">{{ t('title.yourPlan') }}</p>
      <p class="elv-landing-subscription-header__plan">{{ planTypeName }}</p>
    </div>
    <ProjectSettingForm
      :showFormList="showFormList"
      :planType="currentProjectPlan"
      @onCreateProjectSuccess="onCreateProjectSuccess"
    />
  </el-scrollbar>
  <ElvMessageBox
    ref="cancelProjectMessageBoxRef"
    class="elv-cancel-project-message-box"
    :confirm-button-text="t('button.cancelProject')"
    :cancel-button-text="t('button.continueCreating')"
    :title="t('title.confirmCancelation')"
    :loading="cancelLoading"
    @onCancelEvent="onChangeCancelProjectDialogStatus"
    @onConfirmEvent="onCancelProject"
  >
    <template #content>
      <span class="elv-confirm-export-info">{{ t('message.wantToCancelProject') }}</span></template
    >
  </ElvMessageBox>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { isMobile } from '@/lib/utils'
import { ElMessage } from 'element-plus'
import ProjectApi from '@/api/ProjectApi'
import { ProjectSubscribeStatus } from '#/ProjectTypes'
import { useGlobalStore } from '@/stores/modules/global'
import { useProjectStore } from '@/stores/modules/project'
import { useMenuGlobalStore } from '@/stores/modules/global/menu'
import ProjectSettingForm from '@/components/Project/ProjectSettingForm.vue'

const cancelLoading = ref(false)
const currentProjectId = ref('')
const paymentLoading = ref(false)
const isWaitingPayment = ref(false)
const cancelProjectMessageBoxRef = useTemplateRef('cancelProjectMessageBoxRef')

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const globalStore = useGlobalStore()
const projectStore = useProjectStore()
const menuGlobalStore = useMenuGlobalStore()

const showFormList = ['name', 'companyName', 'region', 'employees']

const contentWidth = computed(() => {
  return isMobile() || !menuGlobalStore.isFixedMenu ? 'calc(100vw - 48px)' : 'calc(100vw - 179px)'
})

const currentProjectPlan = computed(() => {
  // eslint-disable-next-line
  return history?.state?.projectPlan || route.params?.projectPlan || 'BASIC'
})

const planTypeName = computed(() => {
  // eslint-disable-next-line
  const projectPlan = history.state?.projectPlan || route.params?.projectPlan
  if (!projectPlan) return ''
  if (projectPlan === 'BASIC') {
    return t('project.basicEdition')
  }
  if (projectPlan === 'STANDARD') {
    return t('project.standardEdition')
  }
  return ''
})

/**
 * 跳转到绑卡界面
 */
const onBindCard = async () => {
  paymentLoading.value = true
  const successUrl = `${import.meta.env.VITE_APP_BASE_URL}/entity/${currentProjectId.value}/settings?projectId=${currentProjectId.value}&fromTab=payment`
  const cancelUrl = `${import.meta.env.VITE_APP_BASE_URL}/landing/subscription?projectId=${currentProjectId.value}`
  try {
    const data = await ProjectApi.subscribeProject(currentProjectId.value, {
      successUrl,
      cancelUrl
    })
    window.open(data.data, '_self')
  } catch (error: any) {
    ElMessage.error(error.message)
    window.open(cancelUrl, '_self')
  } finally {
    paymentLoading.value = false
  }
}

/**
 * 创建项目完成后操作
 */
const onCreateProjectSuccess = () => {
  const projectId = projectStore.projectDetail?.projectId || ''
  currentProjectId.value = projectId
  onBindCard()
}

const onChangeCancelProjectDialogStatus = () => {
  if (paymentLoading.value) return
  cancelProjectMessageBoxRef.value?.onCheckMessageBoxDialog()
}

/**
 * 取消支付绑定，删除项目操作
 */
const onCancelProject = async () => {
  try {
    cancelLoading.value = true
    await ProjectApi.deleteProject(currentProjectId.value)
    await globalStore.fetchProjectList()
    menuGlobalStore.menuListInit()
    cancelLoading.value = false
    onChangeCancelProjectDialogStatus()
    // 跳转到我的项目列表
    router.push({ path: '/' })
  } catch (error: any) {
    ElMessage.error(error.message)
  }
}

watch(
  () => route,
  async (newValue: any) => {
    if (newValue.name === 'landing-subscription') {
      currentProjectId.value = (route.query.projectId as string) || ''
      if (currentProjectId.value) {
        const currentProject = globalStore.projectDetail(currentProjectId.value)
        if (currentProject?.subscribeStatus === ProjectSubscribeStatus.WAIT_PAYMENT) {
          isWaitingPayment.value = true
        }
      }
    }
  },
  { immediate: true, deep: true }
)
</script>

<style lang="scss">
.elv-cancel-project-message-box {
  button {
    width: auto !important;
  }
}
</style>

<style lang="scss" scoped>
.elv-landing-subscription-page-header {
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  font-family: 'Plus Jakarta Sans';
  font-weight: 700;
  font-size: 16px;
  color: #0e0f11;
  border-bottom: 1px solid #dde1e6;
  padding-left: 20px;

  img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
}

.elv-landing-subscription-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 48px);
  width: v-bind('contentWidth');
  box-sizing: border-box;
  padding-top: 80px;
  position: relative;

  :deep(.el-scrollbar__wrap) {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .el-scrollbar__view {
      height: 100%;
    }
  }
}

.elv-landing-subscription-waiting-payment {
  height: calc(100vh - 48px);
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 150px;

  .elv-landing-wait-payment-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Plus Jakarta Sans';

    .elv-landing-wait-payment-content__title {
      font-size: 20px;
      font-weight: 600;
      line-height: 25px;
      color: #0e0f11;
      margin-top: 16px;
    }

    .elv-landing-wait-payment-content__tip {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      color: #1e2024;
      margin-top: 24px;
    }

    .elv-landing-wait-payment-content__cancel {
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      color: #1753eb;
      margin-top: 8px;
      text-decoration: underline;
      cursor: pointer;
    }

    button {
      width: 191px;
      height: 42px;
      margin-top: 32px;
      background-color: #1753eb;
      color: #ffffff;
      font-size: 12px;
      font-weight: 500;
    }
  }
}

.elv-landing-subscription-close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.elv-landing-subscription-header {
  display: flex;
  flex-direction: column;

  .elv-landing-subscription-header__title {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #aaafb6;
    margin-bottom: 8px;
  }

  .elv-landing-subscription-header__plan {
    width: 574px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    font-size: 28px;
    line-height: 35px;
    color: #0e0f11;
  }
}

.elv-apply-project-form {
  margin-top: 16px;
}
</style>
